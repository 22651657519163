import firebase from 'firebase/app'
import React, { Component } from 'react'
import { Button, Container, Icon, Menu, Responsive, Segment, Sidebar, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Footer from './components/Footer'
import { UPDATE_FORM, ROOT_UDPATE } from './redux'
import { getOrdered } from './utils'
import { withRouter } from 'react-router-dom'

const logo = require('./static/logo.png')
const pesrLogo = require('./static/pesr-logo.png')

const DesktopContainer = ({ children, account, turnOffAdmin, content, currentPathname }) => (
  <Responsive getWidth={() => window.innerWidth} minWidth={Responsive.onlyTablet.minWidth}>
    <Segment inverted textAlign="center" style={{ padding: 0 }} vertical>
      <Menu
        inverted
        size="large"
        style={{
          backgroundColor: window.location.hostname.includes('pesrtraining') ? '#12283d' : null,
          marginBottom: 40,
        }}
      >
        <Container>
          <Link to="/">
            <img
              style={{
                zIndex: 99,
                boxShadow: '0px 0px 5px #333',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                backgroundColor: '#f5f5f5',
                width: 185,
                marginBottom: -76,
                position: 'relative',
                bottom: 5,
                padding: 10,
                marginRight: 15,
              }}
              src={window.location.hostname.includes('pesrtraining') ? pesrLogo : logo}
              alt="Tradewind logo"
            />
          </Link>
          {account ? (
            <>
              {account.isAdmin && (
                <>
                  <Menu.Item
                    to="/users"
                    as={Link}
                    className="remove-border-menu-item"
                    active={currentPathname.startsWith('/users')}
                  >
                    Users
                  </Menu.Item>
                  <Menu.Item
                    to="/workshops"
                    as={Link}
                    className="remove-border-menu-item"
                    active={currentPathname.startsWith('/workshops')}
                  >
                    Workshops
                  </Menu.Item>
                  <Menu.Item
                    to="/software"
                    as={Link}
                    className="remove-border-menu-item"
                    active={currentPathname.startsWith('/software')}
                  >
                    Software Licenses
                  </Menu.Item>
                  <Menu.Item
                    to="/settings"
                    as={Link}
                    className="remove-border-menu-item"
                    active={currentPathname.startsWith('/settings')}
                  >
                    Settings
                  </Menu.Item>
                </>
              )}
              {account.accepted && (
                <Dropdown
                  item
                  text="Course Content"
                  className={
                    currentPathname.startsWith('/content')
                      ? 'active remove-border-menu-item'
                      : 'remove-border-menu-item'
                  }
                >
                  <Dropdown.Menu>
                    {content &&
                      getOrdered(account.isAdmin, content).map((categorySlug) => (
                        <Dropdown key={categorySlug} item text={content[categorySlug].title}>
                          <Dropdown.Menu>
                            {content[categorySlug].articles &&
                              getOrdered(account.isAdmin, content[categorySlug].articles).map((articleSlug) => (
                                <Dropdown.Item
                                  key={articleSlug}
                                  as={Link}
                                  to={`/content/${categorySlug}/${articleSlug}`}
                                >
                                  {content[categorySlug].articles[articleSlug].title}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Menu.Menu position="right">
                <Menu.Item
                  className="remove-border-menu-item"
                  to="/account"
                  as={Link}
                  active={currentPathname.startsWith('/account')}
                >
                  My Account
                </Menu.Item>
                {account.isAdmin && (
                  <Menu.Item className="remove-border-menu-item" onClick={turnOffAdmin}>
                    Simulate User
                  </Menu.Item>
                )}
                <Menu.Item className="remove-border-menu-item" onClick={() => firebase.auth().signOut()}>
                  Log out
                </Menu.Item>
              </Menu.Menu>
            </>
          ) : (
            <Menu.Item position="right" className="remove-border-menu-item">
              <Button inverted as={Link} to="/login">
                Log in
              </Button>
              <Button inverted as={Link} primary style={{ marginLeft: '0.5em' }} to="/access">
                Request Access
              </Button>
            </Menu.Item>
          )}
        </Container>
      </Menu>
    </Segment>
    {children}
    <Footer />
  </Responsive>
)

class MobileContainer extends Component {
  state = {}

  componentWillMount = () => {
    this.props.updateCurrentPathname(window.location.pathname)
    this.unlisten = this.props.history.listen(({ pathname }) => this.props.updateCurrentPathname(pathname))
  }
  componentWillUnmount = () => {
    this.unlisten()
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children, account, currentPathname, turnOffAdmin } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive as={Sidebar.Pushable} getWidth={() => window.innerWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar as={Menu} animation="push" inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
          <Menu.Item to="/" as={Link}>
            <img src={logo} alt="Tradewind logo" />
          </Menu.Item>
          {account ? (
            <>
              {account.isAdmin && (
                <>
                  <Menu.Item to="/users" as={Link}>
                    Users
                  </Menu.Item>
                  <Menu.Item to="/workshops" as={Link}>
                    Workshops
                  </Menu.Item>
                  <Menu.Item to="/software" as={Link}>
                    Software Licenses
                  </Menu.Item>
                  <Menu.Item to="/settings" as={Link}>
                    Settings
                  </Menu.Item>
                  <Dropdown.Item onClick={turnOffAdmin}>Simulate User</Dropdown.Item>
                </>
              )}
              <Menu.Item to="/content" as={Link}>
                Course Content
              </Menu.Item>
              <Menu.Item to="/account" as={Link} active={currentPathname.startsWith('/account')}>
                Account
              </Menu.Item>
              <Menu.Item onClick={() => firebase.auth().signOut()}>Log out</Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item as={Link} to="/login">
                Log in
              </Menu.Item>
              <Menu.Item as={Link} to="/access">
                Request Access
              </Menu.Item>
            </>
          )}
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened} onClick={() => sidebarOpened && this.handleSidebarHide()}>
          <Segment inverted style={{ padding: '1em 0em', zIndex: 99 }} vertical>
            <div style={{ cursor: 'pointer', display: 'inline', margin: 15 }} onClick={this.handleToggle}>
              <Icon style={{ color: 'white' }} name="sidebar" />
            </div>
            {/* <img style={{ width: 120 }} src={logo} alt="Tradewind logo" /> */}
          </Segment>
          {children}
          <Footer />
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

const MobileContainerWithRouter = withRouter(MobileContainer)

const AppLayout = ({ children, account, ...rest }) => (
  <div style={{ backgroundColor: 'white' }}>
    <DesktopContainer account={account} {...rest}>
      {children}
    </DesktopContainer>
    <MobileContainerWithRouter account={account} {...rest}>
      {children}
    </MobileContainerWithRouter>
  </div>
)

export default connect(
  ({ account, content, currentPathname }) => ({ account, content, currentPathname }),
  (d) => ({
    turnOffAdmin: () => d({ type: UPDATE_FORM, formType: 'account', payload: { isAdmin: false } }),
    updateCurrentPathname: (currentPathname) => d({ type: ROOT_UDPATE, payload: { currentPathname } }),
  }),
)(AppLayout)
