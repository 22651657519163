import firebase from 'firebase/app'
import React from 'react'
import { Header, Table, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ChangePasswordModal from './ChangePasswordModal'
import UserLicensesModal from './UserLicensesModal'
import FirebaseToggle from './FirebaseToggle'

const UsersTable = ({ users, workshops }) => {
  if (!users || !workshops) return null
  return (
    <Table celled unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name &amp; Email</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>Last Login</Table.HeaderCell>
          <Table.HeaderCell>Accepted</Table.HeaderCell>
          <Table.HeaderCell>Workshops</Table.HeaderCell>
          <Table.HeaderCell>Licenses</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {users.map(([uid, user]) => (
          <Table.Row key={uid}>
            <Table.Cell>
              <Header as="h4">
                {user.firstName} {user.lastName}
                <br />
                <span style={{ fontWeight: 'normal' }}>{user.email}</span>
              </Header>
            </Table.Cell>
            <Table.Cell>{moment(user.createdDate, 'x').format('ll')}</Table.Cell>
            <Table.Cell>{moment(user.lastLogin, 'x').format('ll')}</Table.Cell>
            <Table.Cell collapsing>
              <FirebaseToggle label="" size="small" value={user.accepted} path={`users/${uid}/accepted`} />
            </Table.Cell>
            <Table.Cell>
              {user.workshops
                ? Object.keys(user.workshops).map((joinCode) => (
                    <div key={joinCode}>
                      <Link to={`/workshops/${joinCode}`}>
                        {workshops[joinCode] ? moment(workshops[joinCode].date, 'x').format('ll') : null} ({joinCode})
                      </Link>
                    </div>
                  ))
                : 'Not Enrolled'}
            </Table.Cell>
            <Table.Cell collapsing>
              <UserLicensesModal uid={uid} licenses={user.licenses} />
            </Table.Cell>
            <Table.Cell collapsing>
              <ChangePasswordModal uid={uid} />
              <Button
                icon="trash"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this workshop?')) {
                    firebase
                      .database()
                      .ref(`users/${uid}`)
                      .remove()
                      .then(() => firebase.database().ref(`userDeletions/${uid}`).set(true))
                  }
                }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
export default UsersTable
