export const initialState = {
  currentPathname: '',
  account: null,
  content: null,
  users: null,
  workshops: null,
  software: null,
  licenses: null,
  settings: null,
  homePageMarkdown: null,
  warnings: null,
  authUserInput: {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    workshopCode: '',
    error: '',
  },
  categoryForm: {
    title: '',
    slug: '',
  },
  articleForm: {
    title: '',
    slug: '',
  },
  sectionForm: {
    title: '',
    slug: '',
  },
  workshopForm: {
    date: Date.now(),
    location: '',
    city: '',
    seats: 0,
    comments: '',
    status: 'tentative',
  },
  softwareForm: {
    title: '',
  },
  licenseForm: {
    seats: 0,
    softwareKey: '',
    value: '',
    addMultiple: '',
    url: false,
  },
}

export const ROOT_UDPATE = 'ROOT_UDPATE'
export const UPDATE_AUTH_USER_INPUT = 'UPDATE_AUTH_USER_INPUT'
export const UPDATE_FORM = 'UPDATE_FORM'
export const CLEAR_FORM = 'CLEAR_FORM'

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ROOT_UDPATE:
      return { ...state, ...action.payload }
    case UPDATE_AUTH_USER_INPUT:
      return {
        ...state,
        authUserInput: { ...state.authUserInput, ...action.payload },
      }
    case UPDATE_FORM:
      return {
        ...state,
        [action.formType]: { ...state[action.formType], ...action.payload },
      }
    case CLEAR_FORM:
      return {
        ...state,
        [action.formType]: initialState[action.formType],
      }
    default:
      return state
  }
}
