import React from 'react'
import { Header, Icon, Table, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { UPDATE_FORM } from '../redux'
import { Link } from 'react-router-dom'
import { filterOutCompletedAndSortByDate } from '../utils'
import WorkshopForm from './WorkshopForm'
import moment from 'moment'

const WorkshopsList = ({ workshops, account, updateFormFields }) => {
  const [showForm, updateShowForm] = React.useState(false)
  const [editingKey, updateEditingKey] = React.useState(null)
  if (!workshops || !account.isAdmin) return null
  const sortedWorkshops = filterOutCompletedAndSortByDate(workshops)
  return (
    <>
      <Header
        as="h3"
        style={{
          fontSize: '2.7em',
          fontWeight: 'normal',
          marginBottom: 0,
        }}
      >
        Workshops
      </Header>
      <div style={{ overflowX: 'auto' }}>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>City</Table.HeaderCell>
              <Table.HeaderCell>Seats</Table.HeaderCell>
              <Table.HeaderCell>Join Code</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {sortedWorkshops.map(([key, workshop]) => (
              <Table.Row key={key}>
                <Table.Cell>
                  <Link to={`/workshops/${key}`}>
                    <Header as="h4">{moment(workshop.date, 'x').format('ll')}</Header>
                  </Link>
                </Table.Cell>
                <Table.Cell>{workshop.location}</Table.Cell>
                <Table.Cell>{workshop.city}</Table.Cell>
                <Table.Cell>{workshop.seats}</Table.Cell>
                <Table.Cell>
                  <Link to={`/workshops/${key}`}>{key}</Link>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    icon="pencil"
                    onClick={() => {
                      updateEditingKey(key)
                      updateFormFields(workshop)
                      updateShowForm(true)
                    }}
                  />
                  {/* <Button
                    icon="trash"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this workshop?')) {
                        firebase
                          .database()
                          .ref(`workshops/${key}`)
                          .remove()
                      }
                    }}
                  /> */}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            {/* <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Menu floated="right" pagination>
                <Menu.Item as="a" icon>
                  <Icon name="chevron left" />
                </Menu.Item>
                <Menu.Item as="a">1</Menu.Item>
                <Menu.Item as="a">2</Menu.Item>
                <Menu.Item as="a">3</Menu.Item>
                <Menu.Item as="a">4</Menu.Item>
                <Menu.Item as="a" icon>
                  <Icon name="chevron right" />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row> */}
            <Table.Row style={{ cursor: 'pointer' }} onClick={() => (showForm ? null : updateShowForm(true))}>
              <Table.HeaderCell colSpan="6">
                {showForm ? (
                  <WorkshopForm
                    editingKey={editingKey}
                    hideForm={() => {
                      updateShowForm(false)
                      updateEditingKey(null)
                    }}
                  />
                ) : (
                  <span style={{ color: 'grey' }}>
                    <Icon name="plus" /> Add New Workshop
                  </span>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </>
  )
}
export default connect(
  ({ workshops, account }) => ({ workshops, account }),
  d => ({
    updateFormFields: payload => d({ type: UPDATE_FORM, formType: 'workshopForm', payload }),
  }),
)(WorkshopsList)
