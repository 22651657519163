import firebase from 'firebase/app'
import React from 'react'
import { connect } from 'react-redux'
import { Header, Grid, Segment, Button } from 'semantic-ui-react'
import moment from 'moment'
import { getWorkshopStudents, getAvailableSeats } from '../utils'
import UsersTable from '../components/UsersTable'

const assignLicneses = (students, allLicenses, software, users, workshopJoinCode) => {
  if (!software || !allLicenses || !students) return
  const availableSeats = getAvailableSeats(users, allLicenses)
  const activeSoftware = Object.entries(software).filter(([_, { active }]) => active)
  students.forEach(([uid, { licenses, accepted }]) => {
    if (!accepted) return
    activeSoftware.forEach(([softKey]) => {
      if (!availableSeats) {
        return firebase
          .database()
          .ref(`warnings`)
          .push({ date: Date.now(), message: 'No availabel seats', meta: { softwareKey: softKey, uid } })
      }
      if (!availableSeats.hasOwnProperty(softKey)) {
        return firebase
          .database()
          .ref(`warnings`)
          .push({ date: Date.now(), message: 'Active software has no key', meta: { softwareKey: softKey, uid } })
      }
      const userHasSoftware = licenses
        ? Object.entries(licenses).find(
            ([_, { softwareKey, joinCode }]) => softKey === softwareKey && workshopJoinCode === joinCode,
          )
        : licenses
      if (typeof userHasSoftware !== 'undefined') return
      if (availableSeats[softKey].length === 0) return
      availableSeats[softKey][0].seats = availableSeats[softKey][0].seats - 1
      const { value, url, licenseKey } = availableSeats[softKey][0]
      firebase
        .database()
        .ref(`users/${uid}/licenses/${licenseKey}`)
        .update({ value, url, softwareKey: softKey, softwareTitle: software[softKey].title, workshopJoinCode })
      if (availableSeats[softKey][0].seats < 1) {
        if (availableSeats[softKey].length < 1) {
          delete availableSeats[softKey]
        } else {
          availableSeats[softKey] = availableSeats[softKey].splice(1)
        }
      }
    })
  })
}

const acceptAll = (students) => students.map(([uid]) => firebase.database().ref(`users/${uid}/accepted`).set(true))

const WorkshopDetailPage = ({ workshop, workshopId, workshops, students, licenses, software, users }) => (
  <>
    <Segment.Group>
      <Segment>
        <Grid columns={4}>
          <Grid.Row>
            <Grid.Column>
              <Header
                as="h4"
                content="WORKSHOP DATE"
                style={{
                  color: '#ccc',
                  marginBottom: 0,
                }}
              />
              <Header
                as="h2"
                style={{
                  marginTop: 0,
                }}
                content={workshop.date ? moment(workshop.date, 'x').format('ll') : ''}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                as="h4"
                content="LOCATION"
                style={{
                  color: '#ccc',
                  marginBottom: 0,
                }}
              />
              <Header
                as="h2"
                content={workshop.location}
                style={{
                  marginTop: 0,
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                as="h4"
                content="CITY"
                style={{
                  color: '#ccc',
                  marginBottom: 0,
                }}
              />
              <Header
                as="h2"
                content={workshop.city}
                style={{
                  marginTop: 0,
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                as="h4"
                content="SEATS AVAILABLE"
                style={{
                  color: '#ccc',
                  marginBottom: 0,
                }}
              />
              <Header
                as="h2"
                style={{
                  marginTop: 0,
                }}
              >
                {workshop.seats ? workshop.seats - students.length : 0} of {workshop.seats}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment secondary>
        <Button primary onClick={() => assignLicneses(students, licenses, software, users, workshopId)}>
          Assign Licenses
        </Button>
        <Button primary onClick={() => acceptAll(students)} floated="right">
          Accept All
        </Button>
      </Segment>
    </Segment.Group>
    <UsersTable users={students} workshops={workshops} />
  </>
)

export default connect(({ workshops, users, licenses, software }, { match: { params: { workshopId } } }) => ({
  workshop: workshops && workshops[workshopId] ? workshops[workshopId] : {},
  students: users ? getWorkshopStudents(users, workshopId) : [],
  workshopId,
  licenses,
  users,
  workshops,
  software,
}))(WorkshopDetailPage)
