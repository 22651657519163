import React from 'react'
import { Container, Header, Button, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const HomepageHeading = ({ account, settings }) => (
  <Segment
    inverted
    textAlign="center"
    style={{
      backgroundColor: window.location.hostname.includes('pesrtraining') ? '#12283d' : null,
      padding: '1em 0em',
      minHeight: 500,
      marginTop: -40,
    }}
    vertical
  >
    <Container text>
      <Header
        as="h1"
        content={settings ? settings.HomePageTitle.value : ''}
        inverted
        style={{
          fontSize: '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '2.7em',
        }}
      />
      <Header
        as="h2"
        content={settings ? settings.HomePageSubtitle.value : ''}
        inverted
        style={{
          fontSize: '1.7em',
          fontWeight: 'normal',
          marginTop: '1.5em',
        }}
      />
      {account && account.accepted ? (
        <Button as={Link} to="/content" primary size="large">
          Course Content
        </Button>
      ) : !account ? (
        <Button as={Link} to="/access" primary size="large">
          Request Access
        </Button>
      ) : null}
    </Container>
  </Segment>
)

export default connect(({ account, settings }) => ({ account, settings }))(HomepageHeading)
