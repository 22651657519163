import firebase from 'firebase/app'
import React from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { UPDATE_AUTH_USER_INPUT } from '../redux'

const LoginPage = ({ authUserInput, updateAuthUserInput, login, history }) => (
  <Grid textAlign="center" style={{ minHeight: '100vh' }} verticalAlign="middle">
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as="h2" textAlign="center">
        Log-in to Your Account
      </Header>
      <Form size="large">
        <Segment>
          <p style={{ color: 'red' }}>{authUserInput.error}</p>
          <Form.Input
            fluid
            value={authUserInput.email}
            placeholder="E-mail address"
            type="email"
            onChange={({ target: { value } }) => updateAuthUserInput('email', value)}
          />
          <Form.Input
            fluid
            value={authUserInput.password}
            placeholder="Password"
            type="password"
            onChange={({ target: { value } }) => updateAuthUserInput('password', value)}
          />
          <Button
            primary
            fluid
            size="large"
            onClick={() => login(authUserInput.email, authUserInput.password, history)}
          >
            Login
          </Button>
        </Segment>
      </Form>
      <Message>
        New to us? <Link to="/access">Request Access</Link>
      </Message>
      <Message>
        Forgot password? <Link to="/reset-password">Reset Password</Link>
      </Message>
    </Grid.Column>
  </Grid>
)

export default connect(
  ({ authUserInput }) => ({ authUserInput }),
  (d) => ({
    updateAuthUserInput: (key, value) => d({ type: UPDATE_AUTH_USER_INPUT, payload: { [key]: value, error: '' } }),
    login: (email, password, history) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => history.push('/'))
        .catch((error) => {
          console.warn(error)
          d({ type: UPDATE_AUTH_USER_INPUT, payload: { error: error.message } })
        })
    },
  }),
)(LoginPage)
