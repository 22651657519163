import firebase from 'firebase/app'
import React from 'react'
import { Checkbox } from 'semantic-ui-react'

export default class FirebaseToggle extends React.Component {
  state = {
    value: false,
    value2: false,
  }
  componentDidMount = () => {
    if (!this.props.value) {
      this.turnOn(this.props.path)
      if (this.props.path2) this.turnOn(this.props.path2, true)
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.props.value && (this.props.path !== prevProps.path || this.props.path2 !== prevProps.path2)) {
      this.turnOff(prevProps.path)
      this.turnOn(this.props.path)
    }
    if (!this.props.value && this.props.path2 !== prevProps.path2) {
      this.turnOff(prevProps.path2)
      this.turnOn(this.props.path2, true)
    }
  }

  componentWillUnmount = () => this.turnOff(this.props.path)

  turnOn = (path, value2) =>
    firebase
      .database()
      .ref(path)
      .on('value', (snap) =>
        value2
          ? this.setState({ value2: snap.val() ? true : false })
          : this.setState({ value: snap.val() ? true : false }),
      )

  turnOff = (path) => firebase.database().ref(path).off('value')

  render() {
    const { path, path2, value, ...rest } = this.props
    return (
      <Checkbox
        {...rest}
        checked={value ? value : this.state.value && this.state.value2}
        toggle
        onChange={(_, { checked }) => {
          firebase.database().ref(path).set(checked)
          if (path2) {
            firebase.database().ref(path2).set(checked)
          }
        }}
      />
    )
  }
}
