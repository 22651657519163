import firebase from 'firebase/app'
import React from 'react'
import { Button, Modal, Form } from 'semantic-ui-react'

const ChangePasswordModal = ({ uid }) => {
  const [modalOpen, updateModalOpen] = React.useState(false)
  const [pass, updatePass] = React.useState('')
  return (
    <Modal open={modalOpen} size="mini" trigger={<Button onClick={() => updateModalOpen(true)} icon="lock" />}>
      <Modal.Header>Change Password</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={() =>
            firebase
              .database()
              .ref(`passwordChanges/${uid}`)
              .set(pass)
              .then(() => updateModalOpen(false))
          }
        >
          <Form.Field>
            <input
              type="password"
              autoFocus
              tabIndex={-1}
              placeholder="New Password"
              value={pass}
              onChange={({ target: { value } }) => updatePass(value)}
            />
          </Form.Field>
          <Button fluid size="large" primary style={{ marginTop: 12 }} type="submit">
            Change Password
          </Button>
        </Form>
        <Button onClick={() => updateModalOpen(false)} basic fluid style={{ marginTop: 10 }} type="button">
          Cancel
        </Button>
      </Modal.Content>
    </Modal>
  )
}

export default ChangePasswordModal
