import firebase from 'firebase/app'
import React from 'react'
import { Header, Icon, Grid, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { reorder, getOrdered } from '../utils'

const addSection = (categorySlug, articleSlug, { title, slug }, order, clearForm) =>
  firebase
    .database()
    .ref(`content/${categorySlug}/articles/${articleSlug}/sections/${slug}`)
    .set({ order, title }, clearForm)

const onDragEnd = (categorySlug, articleSlug, items) => ({ source, destination }) => {
  if (!destination) return
  reorder(items, source.index, destination.index).map((item, order) =>
    firebase.database().ref(`content/${categorySlug}/articles/${articleSlug}/sections/${item}/order`).set(order),
  )
}

const SectionEditor = ({ article, categorySlug, articleSlug, sectionForm, clearForm, updateFormField, account }) => {
  const [showForm, updateShowForm] = React.useState(false)
  const sectionOrder = getOrdered(account.isAdmin, article.sections)
  return (
    <DragDropContext onDragEnd={onDragEnd(categorySlug, articleSlug, sectionOrder)}>
      <Droppable droppableId="sectionEditor">
        {({ droppableProps, innerRef: draggableRef, placeholder }) => (
          <div ref={draggableRef} {...droppableProps} className="ui segments">
            {sectionOrder.map((sectionSlug, catIndex) => (
              <Draggable key={sectionSlug} draggableId={sectionSlug} index={catIndex}>
                {({ innerRef, draggableProps, dragHandleProps }) => (
                  <div ref={innerRef} {...draggableProps} className="ui segment">
                    <Link to={`/content/${categorySlug}/${articleSlug}/${sectionSlug}`}>
                      <Header as="h3">
                        {article.sections[sectionSlug].title}
                        <Icon
                          style={{ float: 'right', fontSize: 15 }}
                          name="arrows alternate vertical"
                          {...dragHandleProps}
                        />
                      </Header>
                    </Link>
                  </div>
                )}
              </Draggable>
            ))}
            {placeholder}
            {showForm ? (
              <div className="ui secondary segment">
                <Form
                  onSubmit={() =>
                    addSection(categorySlug, articleSlug, sectionForm, sectionOrder.length, () => {
                      updateShowForm(false)
                      clearForm()
                    })
                  }
                >
                  <Grid stackable columns={2}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Section Title</label>
                        <input
                          required
                          onChange={({ target: { value } }) => updateFormField('title', value)}
                          value={sectionForm.title}
                          placeholder="Section Title"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Section Slug</label>
                        <input
                          required
                          onChange={({ target: { value } }) => updateFormField('slug', value)}
                          value={sectionForm.slug}
                          placeholder="section-slug"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column>
                      <Button type="submit" primary>
                        Submit
                      </Button>
                      <Button type="submit" floated="right" onClick={() => updateShowForm(false)}>
                        Cancel
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </div>
            ) : (
              <div onClick={() => updateShowForm(true)} className="ui secondary segment" style={{ cursor: 'pointer' }}>
                <p>
                  <Icon name="plus" /> Add New Section
                </p>
              </div>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SectionEditor
