import firebase from 'firebase/app'
import React from 'react'
import ReactMde from 'react-mde'
import Showdown from 'showdown'
import ReactMarkdown from 'react-markdown'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

export default class MarkdownEditor extends React.Component {
  state = {
    sectionMarkdown: '',
    value: '',
    selectedTab: 'write',
  }
  componentDidMount() {
    this.turnOn(this.props.sectionSlug)
  }
  componentDidUpdate(prevProps) {
    if (this.props.sectionSlug !== prevProps.sectionSlug) {
      this.turnOff(prevProps.sectionSlug)
      this.turnOn(this.props.sectionSlug)
    }
  }
  componentWillUnmount() {
    this.turnOff(this.props.sectionSlug)
  }
  turnOn(sectionSlug) {
    firebase
      .database()
      .ref(`sections/${sectionSlug}/markdown`)
      .on('value', (snap) => {
        const sectionMarkdown = snap.val()
        if (sectionMarkdown) {
          this.props.updateSectionMarkdown(sectionMarkdown)
          this.setState({ sectionMarkdown })
        } else {
          this.props.updateSectionMarkdown('')
          this.setState({ sectionMarkdown: '' })
        }
      })
  }
  turnOff(sectionSlug) {
    firebase.database().ref(`sections/${sectionSlug}/markdown`).off('value')
  }
  render() {
    return (
      <div className="ui container" style={{ marginTop: 40 }}>
        {this.props.editing ? (
          <ReactMde
            minEditorHeight={500}
            value={this.props.sectionMarkdown}
            selectedTab={this.state.selectedTab}
            onTabChange={(selectedTab) => this.setState({ selectedTab })}
            onChange={this.props.updateSectionMarkdown}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        ) : (
          <ReactMarkdown source={this.state.sectionMarkdown} escapeHtml={false} />
        )}
      </div>
    )
  }
}
