import firebase from 'firebase/app'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Loader, Dimmer, Segment } from 'semantic-ui-react'

export default class SectionMarkdown extends React.Component {
  state = {
    sectionMarkdown: null,
  }
  componentDidMount() {
    this.turnOn(this.props.sectionSlug)
  }
  componentDidUpdate(prevProps) {
    if (this.props.sectionSlug !== prevProps.sectionSlug) {
      this.turnOff(prevProps.sectionSlug)
      this.turnOn(this.props.sectionSlug)
    }
  }
  componentWillUnmount() {
    this.turnOff(this.props.sectionSlug)
  }
  turnOn(sectionSlug) {
    firebase
      .database()
      .ref(`sections/${sectionSlug}/markdown`)
      .on('value', (snap) => {
        const sectionMarkdown = snap.val()
        if (sectionMarkdown) {
          this.setState({ sectionMarkdown })
        } else {
          this.setState({ sectionMarkdown: '' })
        }
      })
  }
  turnOff(sectionSlug) {
    firebase.database().ref(`sections/${sectionSlug}/markdown`).off('value')
  }
  render() {
    return this.state.sectionMarkdown ? (
      <ReactMarkdown source={this.state.sectionMarkdown} escapeHtml={false} />
    ) : (
      <Segment vertical style={{ height: '50vh' }}>
        <Dimmer inverted active>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
    )
  }
}
