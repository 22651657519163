import firebase from 'firebase/app'
import React from 'react'
import { Form, Button, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { UPDATE_FORM, CLEAR_FORM } from '../redux'

const upsertSoftware = async (editingKey, title, cb) => {
  if (editingKey) {
    firebase.database().ref(`software/${editingKey}`).update({ title }, cb)
  } else {
    firebase.database().ref(`software`).push({ title, active: true }, cb)
  }
}

const SoftwareForm = ({ updateFormField, clearForm, softwareForm, hideForm, editingKey }) => (
  <Form
    onSubmit={() =>
      upsertSoftware(editingKey, softwareForm.title, () => {
        clearForm()
        hideForm()
      })
    }
  >
    <Header as="h3">{editingKey ? 'Update Software' : 'Add New Software'}</Header>
    <Form.Field>
      <label>Software Name</label>
      <input
        required
        onChange={({ target: { value } }) => updateFormField('title', value)}
        value={softwareForm.title}
        placeholder="Software Name"
      />
    </Form.Field>
    <Button type="submit" primary>
      Submit
    </Button>
    <Button
      type="button"
      floated="right"
      onClick={() => {
        hideForm()
        clearForm()
      }}
    >
      Cancel
    </Button>
  </Form>
)

export default connect(
  ({ softwareForm }) => ({ softwareForm }),
  (d) => ({
    updateFormField: (field, value) => d({ type: UPDATE_FORM, formType: 'softwareForm', payload: { [field]: value } }),
    clearForm: () => d({ type: CLEAR_FORM, formType: 'softwareForm' }),
  }),
)(SoftwareForm)
