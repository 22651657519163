import firebase from 'firebase/app'
import React from 'react'
import { connect } from 'react-redux'
import { Grid, Segment, Container, Header, Form, Input, Button, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const addWorkshop = (uid, joinCode, cb) =>
  firebase.database().ref(`users/${uid}/workshops/${joinCode}`).set(Date.now()).then(cb)

const changePassword = (password, confirmPassword, onError) => {
  if (password !== confirmPassword) return onError('Passwords do not match')
  const cu = firebase.auth().currentUser
  cu.updatePassword(password)
    .then(() => onError())
    .catch((error) => onError(error.message))
}

const AccountPage = ({ account, settings }) => {
  const [joinCode, updateJoinCode] = React.useState('')
  const [password, updatePassword] = React.useState('')
  const [confirmPassword, updateConfirmPassword] = React.useState('')
  const [error, updateError] = React.useState('')
  if (!account || !settings) return null
  return (
    <Container style={{ minHeight: '100vh' }}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header
              as="h1"
              content="My Account"
              style={{
                paddingTop: 40,
                marginBottom: 20,
              }}
            />
            <Segment.Group>
              <Segment secondary>
                <Header as="h3">Account Details</Header>
              </Segment>
              <Segment>
                <div className="account-details-left-column">Name</div>
                <div className="account-details-right-column">
                  {account.firstName} {account.lastName}
                </div>
              </Segment>
              <Segment>
                <div className="account-details-left-column">Email</div>
                <div className="account-details-right-column">{account.email}</div>
              </Segment>
              <Segment>
                <div className="account-details-left-column">Course Critque</div>
                <div className="account-details-right-column">
                  <a href={settings.CourseCritiqueLink.value} target="_blank" rel="noopener noreferrer">
                    {settings.CourseCritiqueLinkText.value}
                  </a>
                </div>
              </Segment>
              <Segment>
                <div className="account-details-left-column">Critique Code</div>
                <div className="account-details-right-column">{settings.CritiqueCode.value}</div>
              </Segment>
              <Segment>
                <div className="account-details-left-column">Workshop(s)</div>
                <div className="account-details-right-column">
                  {account.workshops
                    ? Object.keys(account.workshops).map((joinCode) => (
                        <p key={joinCode}>
                          {account.isAdmin ? <Link to={`/workshops/${joinCode}`}>{joinCode}</Link> : joinCode}
                        </p>
                      ))
                    : 'Not Enrolled'}
                </div>
              </Segment>
              <Segment>
                <Form>
                  <Form.Field inline>
                    <Input
                      value={joinCode}
                      onChange={({ target: { value } }) => updateJoinCode(value.toLowerCase())}
                      placeholder="Join Code"
                      maxLength={4}
                    />
                    <Button
                      style={{ height: 37, position: 'relative', top: 2 }}
                      onClick={() => addWorkshop(account.uid, joinCode, () => updateJoinCode(''))}
                    >
                      Join Workshop
                    </Button>
                  </Form.Field>
                </Form>
              </Segment>
              <Segment secondary>
                <Header as="h3">Change Password</Header>
              </Segment>
              <Segment>
                <Form
                  onSubmit={() =>
                    changePassword(password, confirmPassword, (e) => {
                      if (e) return updateError(e)
                      updateError('')
                      updatePassword('')
                      updateConfirmPassword('')
                    })
                  }
                >
                  <p style={{ color: '#d81b60' }}>{error}</p>
                  <Form.Field>
                    <Input
                      value={password}
                      onChange={({ target: { value } }) => updatePassword(value)}
                      placeholder="New Password"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      value={confirmPassword}
                      onChange={({ target: { value } }) => updateConfirmPassword(value)}
                      placeholder="Confirm Password"
                    />
                  </Form.Field>
                  <Button fluid>Change Password</Button>
                </Form>
              </Segment>
            </Segment.Group>
          </Grid.Column>
          <Grid.Column width={10}>
            <Header
              as="h1"
              content="Licenses"
              style={{
                paddingTop: 40,
                marginBottom: 20,
              }}
            />
            <Table fixed unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Software</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                  <Table.HeaderCell>Workshop Join Code</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {account.licenses &&
                  Object.entries(account.licenses).map(([licenseKey, license]) => (
                    <Table.Row key={licenseKey}>
                      <Table.Cell>{license.softwareTitle}</Table.Cell>
                      <Table.Cell>
                        {license.url ? (
                          <a href={license.value} target="_blank" rel="noopener noreferrer">
                            {license.value}
                          </a>
                        ) : (
                          license.value
                        )}
                      </Table.Cell>
                      <Table.Cell>{license.workshopJoinCode}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default connect(({ workshops, account, software, settings }) => ({
  account,
  workshops,
  software,
  settings,
}))(AccountPage)
