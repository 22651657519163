import firebase from 'firebase/app'
import React from 'react'
import { connect } from 'react-redux'
import SectionEditor from '../components/SectionEditor'
import { Header, Icon, Form, Grid, Breadcrumb } from 'semantic-ui-react'
import { UPDATE_FORM, CLEAR_FORM } from '../redux'
import FirebaseToggle from '../components/FirebaseToggle'
import SectionDisplay from '../components/SectionDisplay'
import { orderByOrder } from '../utils'
import { Link } from 'react-router-dom'
import ContentMenu from '../components/ContentMenu'

const ArticlePage = (props) => {
  const [showEdit, updateShowEdit] = React.useState(false)
  const [titleField, updateTitleField] = React.useState('')
  const firstSectionSlug = props.article && props.article.sections ? orderByOrder(props.article.sections)[0] : null
  return props.account && props.account.isAdmin ? (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <ContentMenu categorySlug={props.categorySlug} />
        </Grid.Column>
        <Grid.Column width={10}>
          <Breadcrumb>
            <Breadcrumb.Section>
              <Link to={`/content`}>Contents</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>
              <Link to={`/content/${props.categorySlug}`}>{props.categoryTitle}</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>{props.article.title}</Breadcrumb.Section>
          </Breadcrumb>
          <Header
            as="h3"
            style={{
              fontSize: '2.7em',
              fontWeight: 'normal',
              marginBottom: 0,
            }}
          >
            {showEdit ? (
              <Form
                size="massive"
                onSubmit={() => {
                  firebase
                    .database()
                    .ref(`content/${props.categorySlug}/articles/${props.articleSlug}/title`)
                    .set(titleField)
                  updateTitleField('')
                  updateShowEdit(false)
                }}
              >
                <Form.Field width={8}>
                  <input required onChange={({ target: { value } }) => updateTitleField(value)} value={titleField} />
                </Form.Field>
              </Form>
            ) : (
              <>
                {props.article.title}
                <sup>
                  <Icon
                    style={{ fontSize: 15, marginLeft: 20, cursor: 'pointer' }}
                    onClick={() => {
                      updateShowEdit(true)
                      updateTitleField(props.article.title)
                    }}
                    name="pencil"
                  />
                  <Icon
                    style={{ fontSize: 15, marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this article?')) {
                        firebase.database().ref(`content/${props.categorySlug}/articles/${props.articleSlug}`).remove()
                        props.history.push(`/content/${props.categorySlug}`)
                      }
                    }}
                    name="trash"
                  />
                </sup>
                <span style={{ float: 'right', fontSize: 14 }}>
                  <FirebaseToggle
                    label="Admin Only"
                    size="small"
                    value={props.article.admin}
                    path={`content/${props.categorySlug}/sections/${props.articleSlug}/admin`}
                  />
                </span>
              </>
            )}
          </Header>
          <SectionEditor {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <SectionDisplay sectionSlug={firstSectionSlug} {...props} />
  )
}

export default connect(
  (
    { content, sectionForm, account },
    {
      match: {
        params: { categorySlug, articleSlug },
      },
    },
  ) => ({
    account,
    article: content ? content[categorySlug].articles[articleSlug] : {},
    categoryTitle: content ? content[categorySlug].title : '',
    categorySlug,
    articleSlug,
    sectionForm,
  }),
  (d) => ({
    updateFormField: (field, value) => d({ type: UPDATE_FORM, formType: 'sectionForm', payload: { [field]: value } }),
    clearForm: () => d({ type: CLEAR_FORM, formType: 'sectionForm' }),
  }),
)(ArticlePage)
