import firebase from 'firebase/app'
import React from 'react'
import ReactMde from 'react-mde'
import Showdown from 'showdown'
import { Button, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

const HomePageEditor = ({ homePageMarkdown }) => {
  const [value, updateValue] = React.useState(homePageMarkdown)
  const [selectedTab, updateSelectedTab] = React.useState('write')
  React.useEffect(() => updateValue(homePageMarkdown), [homePageMarkdown])
  return (
    <div style={{ marginTop: 40, position: 'relative' }}>
      <Header
        as="h3"
        style={{
          fontSize: '2.7em',
          fontWeight: 'normal',
          marginBottom: 30,
        }}
      >
        Home Page Content
      </Header>
      <div style={{ position: 'absolute', right: 0, top: 10 }}>
        <Button onClick={() => firebase.database().ref(`homePageMarkdown`).set(value)}>Save</Button>
      </div>
      <div>
        <ReactMde
          minEditorHeight={500}
          value={value ? value : ''}
          selectedTab={selectedTab}
          onTabChange={updateSelectedTab}
          onChange={updateValue}
          generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
        />
      </div>
    </div>
  )
}

export default connect(({ homePageMarkdown }) => ({ homePageMarkdown }))(HomePageEditor)
