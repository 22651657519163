import firebase from 'firebase/app'
import React from 'react'
import { connect } from 'react-redux'
import { Header, Icon, Form, Breadcrumb, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ArticleEditor from '../components/ArticleEditor'
import { UPDATE_FORM, CLEAR_FORM } from '../redux'
import FirebaseToggle from '../components/FirebaseToggle'
import ContentMenu from '../components/ContentMenu'

const CategoryPage = (props) => {
  const [showEdit, updateShowEdit] = React.useState(false)
  const [titleField, updateTitleField] = React.useState('')
  if (!props.category) return null
  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <ContentMenu categorySlug={props.categorySlug} />
        </Grid.Column>
        <Grid.Column width={10}>
          <Breadcrumb>
            <Breadcrumb.Section>
              <Link to={`/content`}>Contents</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>{props.category.title}</Breadcrumb.Section>
          </Breadcrumb>
          {props.account && props.account.isAdmin && (
            <Header
              as="h3"
              style={{
                fontSize: '2.7em',
                fontWeight: 'normal',
                marginBottom: 0,
              }}
            >
              {showEdit ? (
                <Form
                  size="massive"
                  onSubmit={() => {
                    firebase.database().ref(`content/${props.categorySlug}/title`).set(titleField)
                    updateTitleField('')
                    updateShowEdit(false)
                  }}
                >
                  <Form.Field width={8}>
                    <input required onChange={({ target: { value } }) => updateTitleField(value)} value={titleField} />
                  </Form.Field>
                </Form>
              ) : (
                <>
                  {props.category.title}
                  <sup>
                    <Icon
                      style={{ fontSize: 15, marginLeft: 20, cursor: 'pointer' }}
                      onClick={() => {
                        updateShowEdit(true)
                        updateTitleField(props.category.title)
                      }}
                      name="pencil"
                    />
                    <Icon
                      style={{ fontSize: 15, marginLeft: 10, cursor: 'pointer' }}
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this category?')) {
                          firebase.database().ref(`content/${props.categorySlug}`).remove()
                          props.history.push('/content')
                        }
                      }}
                      name="trash"
                    />
                  </sup>
                  <span style={{ float: 'right', fontSize: 14 }}>
                    <FirebaseToggle
                      label="Admin Only"
                      size="small"
                      value={props.category.admin}
                      path={`content/${props.categorySlug}/admin`}
                    />
                  </span>
                </>
              )}
            </Header>
          )}
          <ArticleEditor {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default connect(
  (
    { content, articleForm, account },
    {
      match: {
        params: { categorySlug },
      },
    },
  ) => ({
    account,
    category: content ? content[categorySlug] : {},
    categorySlug,
    articleForm,
  }),
  (d) => ({
    updateFormField: (field, value) => d({ type: UPDATE_FORM, formType: 'articleForm', payload: { [field]: value } }),
    clearForm: () => d({ type: CLEAR_FORM, formType: 'articleForm' }),
  }),
)(CategoryPage)
