import React from 'react'
import UsersTable from '../components/UsersTable'
import { Container, Header, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { dataToCsvURI, getCsvArr } from '../utils'

const UsersList = ({ users, account, workshops }) => {
  if (!users || !account.isAdmin) return null
  return (
    <Container style={{ padding: '40px 10px', minHeight: '100vh' }}>
      <Header
        as="h3"
        style={{
          fontSize: '2.7em',
          fontWeight: 'normal',
          marginBottom: 0,
        }}
      >
        Users
        <Button floated="right" onClick={() => window.open(dataToCsvURI(getCsvArr(users)))}>
          Export to CSV
        </Button>
      </Header>
      <div style={{ overflowX: 'auto' }}>
        <UsersTable users={Object.entries(users)} workshops={workshops} />
      </div>
    </Container>
  )
}
export default connect(({ users, workshops, account, licenses }) => ({ users, workshops, account, licenses }))(
  UsersList,
)
