import React from 'react'
import { Header, Table } from 'semantic-ui-react'
import UpsertSettingModal from './UpsertSettingModal'

const UsersTable = ({ settings }) => (
  <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Key</Table.HeaderCell>
        <Table.HeaderCell>Value</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {settings &&
        Object.entries(settings).map(([settingKey, { value }]) => (
          <Table.Row key={settingKey}>
            <Table.Cell>
              <Header as="h4">{settingKey}</Header>
            </Table.Cell>
            <Table.Cell>{value}</Table.Cell>
            <Table.Cell collapsing>
              <UpsertSettingModal settingKey={settingKey} settingValue={value} />
              {/* <Button
                icon="trash"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this setting?')) {
                    firebase
                      .database()
                      .ref(`settings/${settingKey}`)
                      .remove()
                  }
                }}
              /> */}
            </Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>
  </Table>
)
export default UsersTable
