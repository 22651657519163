import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import AppLayout from './AppLayout'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import RequestAccessPage from './pages/RequestAccessPage'
import ContentPage from './pages/ContentPage'
import WorkshopsPage from './pages/WorkshopsPage'
import ResetPage from './pages/ResetPage'
import UsersPage from './pages/UsersPage'
import SoftwarePage from './pages/SoftwarePage'
import SettingsPage from './pages/SettingsPage'
import AccountPage from './pages/AccountPage'
import { connect } from 'react-redux'
import { Segment, Loader } from 'semantic-ui-react'

const logo = require('./static/logo.png')

const AppRouter = ({ settings, homePageMarkdown }) =>
  settings && homePageMarkdown ? (
    <Router>
      <AppLayout>
        <Route path="/" exact component={HomePage} />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/reset-password" exact component={ResetPage} />
        <Route path="/access" exact component={RequestAccessPage} />
        <Route path="/content" component={ContentPage} />
        <Route path="/workshops" component={WorkshopsPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/software" component={SoftwarePage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/account" component={AccountPage} />
      </AppLayout>
    </Router>
  ) : (
    <Segment vertical style={{ height: '100vh', background: 'white' }}>
      <div style={{ width: 300, margin: '30vh auto' }}>
        <img alt="tradewind technlogies logo" style={{ width: '100%' }} src={logo} />
        <Loader style={{ marginTop: 50 }} active>
          Loading
        </Loader>
      </div>
    </Segment>
  )

export default connect(({ settings, homePageMarkdown }) => ({ settings, homePageMarkdown }))(AppRouter)
