import React from 'react'
import { getOrdered } from '../utils'
import { connect } from 'react-redux'
import { Menu, Accordion, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ContentMenu = ({
  content,
  account,
  categorySlug: activeCategorySlug,
  articleSlug: activeArticleSlug,
  sectionSlug: activeSectionSlug,
}) => {
  const [activeCategory, updateActiveCategory] = React.useState(activeCategorySlug)
  if (!content || !account) return null
  return (
    <Accordion as={Menu} fluid vertical style={{ marginTop: 34 }}>
      {getOrdered(account.isAdmin, content).map(categorySlug => (
        <Menu.Item key={categorySlug}>
          <Accordion.Title
            active={categorySlug === activeCategory}
            onClick={() => updateActiveCategory(categorySlug === activeCategory ? null : categorySlug)}
          >
            {content[categorySlug].title}
            {content[categorySlug].admin && <Icon style={{ float: 'right' }} name="lock" />}
          </Accordion.Title>
          <Accordion.Content active={categorySlug === activeCategory} as={Menu.Menu}>
            {content[categorySlug].articles &&
              getOrdered(account.isAdmin, content[categorySlug].articles).map(articleSlug => (
                <Menu.Item key={articleSlug} active={articleSlug === activeArticleSlug}>
                  <Menu.Header as={Link} to={`/content/${categorySlug}/${articleSlug}/`}>
                    {content[categorySlug].articles[articleSlug].title}
                    {content[categorySlug].articles[articleSlug].admin && (
                      <Icon style={{ float: 'right' }} name="lock" />
                    )}
                  </Menu.Header>
                  <Menu.Menu>
                    {getOrdered(account.isAdmin, content[categorySlug].articles[articleSlug].sections).map(
                      sectionSlug => (
                        <Menu.Item
                          key={sectionSlug}
                          active={sectionSlug === activeSectionSlug}
                          as={Link}
                          to={`/content/${categorySlug}/${articleSlug}/${sectionSlug}`}
                        >
                          {content[categorySlug].articles[articleSlug].sections[sectionSlug].admin && (
                            <Icon name="lock" />
                          )}
                          {content[categorySlug].articles[articleSlug].sections[sectionSlug].title}
                        </Menu.Item>
                      ),
                    )}
                  </Menu.Menu>
                </Menu.Item>
              ))}
          </Accordion.Content>
        </Menu.Item>
      ))}
    </Accordion>
  )
}

export default connect(({ content, account }) => ({ content, account }))(ContentMenu)

/* <Accordion as={Menu} vertical>
  <Menu.Item>
    <Accordion.Title
      active={activeIndex === 0}
      content='Size'
      index={0}
      onClick={this.handleClick}
    />
    <Accordion.Content active={activeIndex === 0} content={SizeForm} />
  </Menu.Item>

  <Menu.Item>
    <Accordion.Title
      active={activeIndex === 1}
      content='Colors'
      index={1}
      onClick={this.handleClick}
    />
    <Accordion.Content active={activeIndex === 1} content={ColorForm} />
  </Menu.Item>
</Accordion> */
