import firebase from 'firebase/app'
import React from 'react'
import { Form, Button, Header, Select, Checkbox, TextArea } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { UPDATE_FORM, CLEAR_FORM } from '../redux'
import { getOptionsFromObj, getKeysFromText } from '../utils'

const upsertLicense = async (editingKey, licenseForm, addMultiple, cb) => {
  if (editingKey) {
    firebase
      .database()
      .ref(`licenses/${editingKey}`)
      .update(
        {
          ...licenseForm,
          keys: addMultiple ? getKeysFromText(licenseForm, () => firebase.database().ref('licenses').push().key) : null,
        },
        cb,
      )
  } else if (addMultiple) {
    const licensesRef = firebase.database().ref(`licenses`)

    licensesRef.update(
      getKeysFromText(licenseForm, () => licensesRef.push().key),
      cb,
    )
  } else {
    firebase
      .database()
      .ref(`licenses`)
      .push({ ...licenseForm, active: true }, cb)
  }
}

const LicenseForm = ({ updateFormField, clearForm, licenseForm, hideForm, editingKey, software }) => {
  const [addMultiple, updateAddMultiple] = React.useState(false)
  return (
    <Form
      onSubmit={() =>
        upsertLicense(editingKey, licenseForm, addMultiple, () => {
          clearForm()
          hideForm()
        })
      }
    >
      <Header as="h3">{editingKey ? 'Update License' : 'Add New License'}</Header>
      <Form.Group widths="equal">
        <Form.Field width={3}>
          <label>Software</label>
          <Select
            required
            placeholder="Select Software"
            value={licenseForm.softwareKey}
            onChange={(_, { value }) => updateFormField('softwareKey', value)}
            options={software !== null ? getOptionsFromObj(software, 'title') : []}
          />
        </Form.Field>
        <Form.Field width={2}>
          <label>Seats</label>
          <input
            required
            type="number"
            placeholder="Number of seats"
            value={licenseForm.seats}
            onChange={({ target: { value } }) => updateFormField('seats', value)}
          />
        </Form.Field>
        <Form.Field width={2}>
          <label>Add Multiple</label>
          <Checkbox
            disabled={!!editingKey}
            checked={addMultiple}
            toggle
            size="small"
            onChange={(_, { checked }) => updateAddMultiple(checked)}
          />
        </Form.Field>
        <Form.Field width={9}>
          <label>
            {addMultiple ? 'Values' : 'Value'}
            <span style={{ marginLeft: 15 }}>
              <Checkbox
                label={addMultiple ? 'URLs' : 'URL'}
                checked={licenseForm.url}
                toggle
                size="mini"
                onChange={(_, { checked }) => updateFormField('url', checked)}
              />
            </span>
          </label>
          {addMultiple ? (
            <TextArea
              placeholder="List one value per line"
              value={licenseForm.addMultiple}
              onChange={({ target: { value } }) => updateFormField('addMultiple', value)}
            />
          ) : (
            <input
              required
              placeholder="Value"
              value={licenseForm.value}
              onChange={({ target: { value } }) => updateFormField('value', value)}
            />
          )}
        </Form.Field>
      </Form.Group>
      {/* <Form.Field>
      <label>Keys</label>
      <TextArea
        placeholder="List License Keys"
        value={licenseForm.keysText}
        onChange={({ target: { value } }) => updateFormField('keysText', value)}
      />
    </Form.Field> */}
      <Button type="submit" primary>
        Submit
      </Button>
      <Button
        type="button"
        floated="right"
        onClick={() => {
          hideForm()
          clearForm()
        }}
      >
        Cancel
      </Button>
    </Form>
  )
}

export default connect(
  ({ software, licenseForm }) => ({ software, licenseForm }),
  (d) => ({
    updateFormField: (field, value) => d({ type: UPDATE_FORM, formType: 'licenseForm', payload: { [field]: value } }),
    clearForm: () => d({ type: CLEAR_FORM, formType: 'licenseForm' }),
  }),
)(LicenseForm)
