import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-mde/lib/styles/css/react-mde-all.css'
import AppRouter from './AppRouter'
import { reducer, ROOT_UDPATE, initialState } from './redux'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

if (window.location.hostname.includes('pesrtraining')) {
  document.body.style.backgroundColor = '#12283d'
  document.title = 'PESR Training Course'
  firebase.initializeApp({
    apiKey: 'AIzaSyCZBn5Fe0J1eDmDsHpgVshYnTwjN7MHH4Q',
    authDomain: 'pesrtraining.firebaseapp.com',
    databaseURL: 'https://pesrtraining.firebaseio.com',
    projectId: 'pesrtraining',
    storageBucket: 'pesrtraining.appspot.com',
    messagingSenderId: '815811354175',
    appId: '1:815811354175:web:a61e200332cb5bd9b3628a',
  })
} else {
  firebase.initializeApp({
    apiKey: 'AIzaSyAcs8claJmM3J52vM7MTlfDgu4c9yrSZxI',
    authDomain: 'pacscourse.firebaseapp.com',
    databaseURL: 'https://pacscourse.firebaseio.com',
    projectId: 'pacscourse',
    storageBucket: 'pacscourse.appspot.com',
    messagingSenderId: '807834044419',
    appId: '1:807834044419:web:60b4320e849f2357',
  })
}

// Object.entries polyfill
if (!Object.entries) {
  Object.entries = (obj) => {
    let ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i)
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

Sentry.init({ dsn: 'https://8bde9f25e5ab41db8a990d1ab622f8e2@sentry.io/1758959' })

const store = createStore(reducer)

const publicStaticData = ['settings', 'homePageMarkdown']

publicStaticData.map((key) =>
  firebase
    .database()
    .ref(key)
    .once('value', (snap) => {
      store.dispatch({ type: ROOT_UDPATE, payload: { [key]: snap.val() } })
    }),
)

let onUid = null

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    let rootListeners = ['content']
    onUid = user.uid
    let isAdmin = false
    try {
      const isAdminSnap = await firebase.database().ref(`admins/${onUid}`).once('value')
      if (isAdminSnap.val()) isAdmin = true
    } catch (e) {}
    firebase
      .database()
      .ref(`users/${onUid}`)
      .on('value', (snap) => {
        const account = { ...snap.val(), user, isAdmin, uid: user.uid }
        store.dispatch({ type: ROOT_UDPATE, payload: { account } })
      })
    firebase.database().ref(`users/${onUid}`).update({ email: user.email, lastLogin: Date.now() })
    if (isAdmin) {
      rootListeners.push('settings', 'workshops', 'software', 'licenses', 'users', 'warnings')
    }
    rootListeners.map((key) =>
      firebase
        .database()
        .ref(key)
        .on('value', (snap) => {
          store.dispatch({ type: ROOT_UDPATE, payload: { [key]: snap.val() } })
        }),
    )
  } else {
    if (onUid) {
      firebase.database().ref(`users/${onUid}`).off('value')
      onUid = null
      store.dispatch({ type: ROOT_UDPATE, payload: { ...initialState } })
      window.location.href = '/'
    }
  }
})

ReactDOM.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
  document.getElementById('root'),
)
