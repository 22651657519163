import React from 'react'
import { Button, Modal, List } from 'semantic-ui-react'
import { connect } from 'react-redux'

const UserLicensesModal = ({ software, licenses }) => {
  const [modalOpen, updateModalOpen] = React.useState(false)
  return (
    <Modal
      open={modalOpen}
      size="mini"
      trigger={
        <Button fluid onClick={() => updateModalOpen(true)}>
          Licenses
        </Button>
      }
    >
      <Modal.Header>Licenses</Modal.Header>
      <Modal.Content>
        <List divided relaxed>
          {licenses &&
            software &&
            Object.entries(licenses).map(([key, { softwareKey, url, value }]) =>
              software[softwareKey] ? (
                <List.Item key={key}>
                  <List.Content>
                    <List.Header>{software[softwareKey].title}</List.Header>
                    <List.Description>
                      {url ? (
                        <a href={value} target="_blank" rel="noopener noreferrer">
                          {value}
                        </a>
                      ) : (
                        value
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ) : null,
            )}
        </List>
        <Button onClick={() => updateModalOpen(false)} basic fluid style={{ marginTop: 10 }} type="button">
          Close
        </Button>
      </Modal.Content>
    </Modal>
  )
}

export default connect(({ software }) => ({ software }))(UserLicensesModal)
