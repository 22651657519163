import React from 'react'
import { Header, Container, Tab, Grid } from 'semantic-ui-react'
import SoftwareTable from '../components/SoftwareTable'
import LicenseTable from '../components/LicenseTable'
import WarningsList from '../components/WarningsList'

const SoftwarePage = () => (
  <Container fluid style={{ padding: '40px 10px', minHeight: '100vh' }}>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={4}>
          <Header
            as="h3"
            style={{
              fontSize: '2.7em',
              fontWeight: 'normal',
              marginBottom: 0,
            }}
          >
            Software
          </Header>
          <Tab
            style={{ overflowX: 'auto' }}
            menu={{ pointing: true, secondary: true }}
            panes={[
              {
                menuItem: 'Active',
                render: () => <SoftwareTable isActive={true} />,
              },
              {
                menuItem: 'Inactive',
                render: () => <SoftwareTable isActive={false} />,
              },
            ]}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Header
            as="h3"
            style={{
              fontSize: '2.7em',
              fontWeight: 'normal',
              marginBottom: 0,
            }}
          >
            Licenses
          </Header>
          <Tab
            style={{ overflowX: 'auto' }}
            menu={{ pointing: true, secondary: true }}
            panes={[
              {
                menuItem: 'Active',
                render: () => <LicenseTable isActive />,
              },
              {
                menuItem: 'Inactive',
                render: () => <LicenseTable />,
              },
            ]}
          />
          <br />
          <br />
          <hr />
          <br />
          <Header
            as="h3"
            style={{
              fontSize: '2.7em',
              fontWeight: 'normal',
              marginBottom: 0,
            }}
          >
            Warnings
          </Header>
          <WarningsList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
)

export default SoftwarePage
