import firebase from 'firebase/app'
import React from 'react'
import { Header, Icon, Grid, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { reorder, getFirstInArray, getOrdered } from '../utils'
import { UPDATE_FORM, CLEAR_FORM } from '../redux'
import CategoryDisplay from './CategoryDisplay'
import ContentMenu from './ContentMenu'

const addCategory = ({ title, slug }, order, clearForm) =>
  firebase.database().ref(`content/${slug}`).set({ title, order }, clearForm)

const onDragEnd = (items) => ({ source, destination }) => {
  if (!destination) return
  reorder(items, source.index, destination.index).map((item, order) =>
    firebase.database().ref(`content/${item}/order`).set(order),
  )
}

const CategoryEditor = ({ content, categoryForm, updateFormField, clearForm, account }) => {
  const [showForm, updateShowForm] = React.useState(false)
  if (!content) return null
  const categoryOrder = getOrdered(account.isAdmin, content)
  const firstCategorySlug = getFirstInArray(categoryOrder)
  const firstArticleSlug = getFirstInArray(getOrdered(account.isAdmin, content[firstCategorySlug].articles))
  const firstSectionSlug = getFirstInArray(
    getOrdered(account.isAdmin, content[firstCategorySlug].articles[firstArticleSlug].sections),
  )
  return account.isAdmin ? (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <ContentMenu categorySlug={firstCategorySlug} />
        </Grid.Column>
        <Grid.Column width={10}>
          <DragDropContext onDragEnd={onDragEnd(categoryOrder)}>
            {content && (
              <Droppable droppableId="categoryEditor">
                {({ droppableProps, innerRef: draggableRef, placeholder }) => (
                  <div ref={draggableRef} {...droppableProps} className="ui segments">
                    {categoryOrder.map((categorySlug, catIndex) => (
                      <Draggable key={categorySlug} draggableId={categorySlug} index={catIndex}>
                        {({ innerRef, draggableProps, dragHandleProps }) => (
                          <div ref={innerRef} {...draggableProps} className="ui segment">
                            <Link to={`/content/${categorySlug}`}>
                              <Header as="h3">
                                {content[categorySlug].title}
                                <Icon
                                  style={{ float: 'right', fontSize: 15 }}
                                  name="arrows alternate vertical"
                                  {...dragHandleProps}
                                />
                              </Header>
                            </Link>
                            <hr />
                            {content[categorySlug].articles &&
                              Object.entries(content[categorySlug].articles).map(([articleSlug, article]) => (
                                <div key={articleSlug} style={{ margin: '10px auto' }}>
                                  <Link to={`/content/${categorySlug}/${articleSlug}`}>
                                    <Header as="h3">{article.title}</Header>
                                  </Link>
                                  {article.sections &&
                                    Object.entries(article.sections).map(([sectionSlug, section]) => (
                                      <div key={sectionSlug} style={{ margin: 10 }}>
                                        <Link to={`/content/${categorySlug}/${articleSlug}/${sectionSlug}`}>
                                          <Header as="h4">{section.title}</Header>
                                        </Link>
                                      </div>
                                    ))}
                                </div>
                              ))}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {placeholder}
                    {showForm ? (
                      <div className="ui secondary segment">
                        <Form
                          onSubmit={() =>
                            addCategory(categoryForm, categoryOrder.length, () => {
                              clearForm()
                              updateShowForm(false)
                            })
                          }
                        >
                          <Grid stackable columns={2}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Category Title</label>
                                <input
                                  required
                                  onChange={({ target: { value } }) => updateFormField('title', value)}
                                  value={categoryForm.title}
                                  placeholder="Category Title"
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Field>
                                <label>Category Slug</label>
                                <input
                                  required
                                  onChange={({ target: { value } }) => updateFormField('slug', value)}
                                  value={categoryForm.slug}
                                  placeholder="category-slug"
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid>
                          <Grid>
                            <Grid.Column>
                              <Button type="submit" primary>
                                Submit
                              </Button>
                              <Button type="submit" floated="right" onClick={() => updateShowForm(false)}>
                                Cancel
                              </Button>
                            </Grid.Column>
                          </Grid>
                        </Form>
                      </div>
                    ) : (
                      <div
                        onClick={() => updateShowForm(true)}
                        className="ui secondary segment"
                        style={{ cursor: 'pointer' }}
                      >
                        <p>
                          <Icon name="plus" /> Add New Category
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <CategoryDisplay
      {...{
        content,
        firstCategorySlug,
        firstArticleSlug,
        firstSectionSlug,
      }}
    />
  )
}
export default connect(
  ({ content, categoryForm, account }) => ({ content, categoryForm, account }),
  (d) => ({
    updateFormField: (field, value) => d({ type: UPDATE_FORM, formType: 'categoryForm', payload: { [field]: value } }),
    clearForm: () => d({ type: CLEAR_FORM, formType: 'categoryForm' }),
  }),
)(CategoryEditor)
