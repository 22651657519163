import React from 'react'
import { Header, Grid, Breadcrumb } from 'semantic-ui-react'
import SectionMarkdown from './SectionMarkdown'
import ContentMenu from './ContentMenu'
import { Link } from 'react-router-dom'

const SectionDisplay = ({ article, categorySlug, articleSlug, sectionSlug, categoryTitle }) => {
  if (!article.sections) return null
  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <ContentMenu categorySlug={categorySlug} articleSlug={articleSlug} sectionSlug={sectionSlug} />
        </Grid.Column>
        <Grid.Column width={10}>
          <Breadcrumb>
            <Breadcrumb.Section>
              <Link to={`/content`}>Contents</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>
              <Link to={`/content/${categorySlug}`}>{categoryTitle}</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>
              <Link to={`/content/${categorySlug}/${articleSlug}`}>{article.title}</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>{article.sections[sectionSlug].title}</Breadcrumb.Section>
          </Breadcrumb>
          <Header
            as="h1"
            style={{
              margin: '30px auto 0px',
            }}
          >
            {article.title}
          </Header>
          <Header
            as="h2"
            style={{
              margin: '0px auto 30px',
            }}
          >
            {article.sections[sectionSlug].title}
          </Header>
          <SectionMarkdown sectionSlug={sectionSlug} />
        </Grid.Column>
        <Grid.Column width={3} />
      </Grid.Row>
    </Grid>
  )
}

export default SectionDisplay
