import firebase from 'firebase/app'
import React from 'react'
import { Grid, Form, Button, Select, Header, TextArea } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { UPDATE_FORM, CLEAR_FORM } from '../redux'
import DatePicker from 'react-datepicker'
import { randomString } from '../utils'

const upsertWorkshop = async (editingKey, workshopFields, cb) => {
  if (editingKey) {
    firebase.database().ref(`workshops/${editingKey}`).update(workshopFields, cb)
  } else {
    const joinCode = randomString(4).toLowerCase()
    const workshopRef = firebase.database().ref(`workshops/${joinCode}`)
    const snap = await workshopRef.once('value')
    if (snap.val() === null) {
      workshopRef.set(workshopFields, cb)
    } else {
      firebase
        .database()
        .ref(`workshops/${randomString(4).toLowerCase()}`)
        .set(workshopFields, cb)
    }
  }
}

const WorkshopForm = ({ updateFormField, clearForm, workshopForm, hideForm, editingKey }) => (
  <Form
    onSubmit={() =>
      upsertWorkshop(editingKey, workshopForm, () => {
        clearForm()
        hideForm()
      })
    }
  >
    <Header as="h3">{editingKey ? 'Update Workshop' : 'Add New Workshop'}</Header>
    <Grid stackable columns={3}>
      <Grid.Column>
        <Form.Field>
          <label>Date</label>
          <DatePicker
            style={{ width: '100%' }}
            selected={workshopForm.date}
            onChange={(dt) => updateFormField('date', dt.getTime())}
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column>
        <Form.Field>
          <label>Paid Seats</label>
          <input
            required
            type="number"
            onChange={({ target: { value } }) => updateFormField('seats', value)}
            value={workshopForm.seats}
            placeholder="Paid Seats"
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column>
        <Form.Field>
          <label>Workshop Status</label>
          <Select
            placeholder="Workshop Status"
            options={[
              { key: 'tentative', value: 'tentative', text: 'Tentative' },
              { key: 'confirmed', value: 'confirmed', text: 'Confirmed' },
              { key: 'completed', value: 'completed', text: 'Completed' },
            ]}
          />
        </Form.Field>
      </Grid.Column>
    </Grid>
    <Grid stackable columns={2}>
      <Grid.Column>
        <Form.Field>
          <label>Location</label>
          <input
            required
            onChange={({ target: { value } }) => updateFormField('location', value)}
            value={workshopForm.location}
            placeholder="Workshop Location"
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column>
        <Form.Field>
          <label>City</label>
          <input
            required
            onChange={({ target: { value } }) => updateFormField('city', value)}
            value={workshopForm.city}
            placeholder="Workshop City"
          />
        </Form.Field>
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Column>
        <Form.Field>
          <label>Comments</label>
          <TextArea
            value={workshopForm.comments}
            onChange={({ target: { value } }) => updateFormField('comments', value)}
            placeholder="Add comments here..."
          />
        </Form.Field>
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Column>
        <Button type="submit" primary>
          Submit
        </Button>
        <Button
          type="button"
          floated="right"
          onClick={() => {
            hideForm()
            clearForm()
          }}
        >
          Cancel
        </Button>
      </Grid.Column>
    </Grid>
  </Form>
)

export default connect(
  ({ workshopForm }) => ({ workshopForm }),
  (d) => ({
    updateFormField: (field, value) => d({ type: UPDATE_FORM, formType: 'workshopForm', payload: { [field]: value } }),
    clearForm: () => d({ type: CLEAR_FORM, formType: 'workshopForm' }),
  }),
)(WorkshopForm)
