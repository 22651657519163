import React from 'react'
import { Icon, Table, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { UPDATE_FORM } from '../redux'
import FirebaseToggle from './FirebaseToggle'
import LicenseForm from './LicenseForm'
import { diffLicenseSeats } from '../utils'

const LicenseTable = ({ licenses, software, updateFormFields, isActive, users }) => {
  const [showForm, updateShowForm] = React.useState(false)
  const [editingKey, updateEditingKey] = React.useState(null)
  const remainingSeats = diffLicenseSeats(users, licenses)
  console.log('remainingSeats', remainingSeats)
  const items = licenses
    ? Object.entries(licenses)
        .filter(([_, { active }]) => active === isActive)
        .sort(([aKey], [bKey]) => {
          return (
            remainingSeats &&
            remainingSeats[aKey] &&
            remainingSeats[bKey] &&
            remainingSeats[bKey].seats - remainingSeats[aKey].seats
          )
        })
    : []
  return (
    <Table celled unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Software</Table.HeaderCell>
          <Table.HeaderCell>Seats</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.HeaderCell>Active</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map(([key, license]) => (
          <Table.Row key={key} disabled={remainingSeats && remainingSeats[key] && remainingSeats[key].seats < 1}>
            <Table.Cell>
              {software && software[license.softwareKey] ? software[license.softwareKey].title : ''}
            </Table.Cell>
            <Table.Cell>
              {remainingSeats && remainingSeats[key] && remainingSeats[key].seats} remaining of {license.seats} Seats
            </Table.Cell>
            <Table.Cell>
              {license.url ? (
                <a href={license.value} target="_blank" rel="noopener noreferrer">
                  {license.value}
                </a>
              ) : (
                license.value
              )}
            </Table.Cell>
            <Table.Cell collapsing>
              <FirebaseToggle
                disabled={remainingSeats && remainingSeats[key] && remainingSeats[key].seats < 1}
                label=""
                size="small"
                value={license.active}
                path={`licenses/${key}/active`}
              />
            </Table.Cell>
            <Table.Cell collapsing>
              <Button
                icon="pencil"
                disabled={remainingSeats && remainingSeats[key] && remainingSeats[key].seats < 1}
                onClick={() => {
                  updateEditingKey(key)
                  updateFormFields(license)
                  updateShowForm(true)
                }}
              />
              {/* <Button
                icon="close"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this license and all its keys?')) {
                    firebase
                      .database()
                      .ref('licenses/' + key)
                      .remove()
                  }
                }}
              /> */}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row style={{ cursor: 'pointer' }} onClick={() => (showForm ? null : updateShowForm(true))}>
          <Table.HeaderCell colSpan="5">
            {showForm ? (
              <LicenseForm
                editingKey={editingKey}
                hideForm={() => {
                  updateShowForm(false)
                  updateEditingKey(null)
                }}
              />
            ) : (
              <span style={{ color: 'grey' }}>
                <Icon name="plus" /> Add New License
              </span>
            )}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}

export default connect(
  ({ software, licenses, users }) => ({ software, licenses, users }),
  d => ({
    updateFormFields: payload => d({ type: UPDATE_FORM, formType: 'licenseForm', payload }),
  }),
)(LicenseTable)
