import React from 'react'
import { Header, Grid } from 'semantic-ui-react'
import ContentMenu from './ContentMenu'
import SectionMarkdown from './SectionMarkdown'

export default ({ content, firstCategorySlug, firstArticleSlug, firstSectionSlug }) => (
  <Grid stackable>
    <Grid.Row>
      <Grid.Column width={3}>
        <ContentMenu categorySlug={firstCategorySlug} articleSlug={firstArticleSlug} sectionSlug={firstSectionSlug} />
      </Grid.Column>
      <Grid.Column width={10}>
        <Header
          as="h1"
          style={{
            margin: '30px auto 0px',
          }}
        >
          {content[firstCategorySlug].articles[firstArticleSlug].title}
        </Header>
        <Header
          as="h2"
          style={{
            margin: '0px auto 30px',
          }}
        >
          {content[firstCategorySlug].articles[firstArticleSlug].sections[firstSectionSlug].title}
        </Header>
        <SectionMarkdown sectionSlug={firstSectionSlug} />
      </Grid.Column>
      <Grid.Column width={3} />
    </Grid.Row>
  </Grid>
)
