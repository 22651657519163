export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const orderByOrder = obj =>
  Object.entries(obj).reduce((prev, [key, { order }]) => {
    prev[order] = key
    return prev
  }, [])

export const sortByDate = obj => Object.entries(obj).sort((a, b) => a[1].date - b[1].date)

export const filterOutCompletedAndSortByDate = obj =>
  Object.entries(obj)
    .filter(([_, val]) => val.status !== 'completed')
    .sort((a, b) => a[1].date - b[1].date)

export const filterOutIncompleteAndSortByDate = obj =>
  Object.entries(obj)
    .filter(([_, val]) => val.status === 'completed')
    .sort((a, b) => a[1].date - b[1].date)

export const filterOutAdmin = obj =>
  Object.entries(obj).reduce((prev, [key, val]) => {
    if (!val.admin) prev[key] = val
    return prev
  }, {})

export const getOrdered = (isAdmin, items) =>
  items ? (isAdmin ? orderByOrder(items) : orderByOrder(filterOutAdmin(items))) : []

export const getFirstInArray = items =>
  items.reduce((prev, first) => {
    if (!prev) return first
    return prev
  }, null)

const chars = '0123456789abcdefghijklmnopqrstuvwxyz'

export const randomString = length => {
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export const getWorkshopStudents = (users, workshopId) =>
  Object.entries(users).filter(([_, { workshops }]) => workshops && workshops.hasOwnProperty(workshopId))

export const getOptionsFromObj = (obj, textKey) =>
  Object.entries(obj).map(([key, value]) => ({ key, value: key, text: value[textKey] }))

export const countLicenses = users =>
  Object.values(users).reduce(
    (prev, { licenses }) => ({
      ...prev,
      ...(licenses
        ? Object.keys(licenses).reduce(
            (prev2, licenseKey) => ({
              ...prev2,
              [licenseKey]: prev[licenseKey] ? prev[licenseKey] + 1 : 1,
            }),
            {},
          )
        : {}),
    }),
    {},
  )

export const diffSeats = (users, licenses) => {
  const counts = countLicenses(users)
  return licenses.reduce((prev, [licenseKey, { seats, value, softwareKey, url }]) => {
    const seatDiff = Number(seats) === 0 ? 9999 : Number(seats) - (counts[licenseKey] ? counts[licenseKey] : 0)
    if (seatDiff > 0) {
      const prevSoftware = prev[softwareKey] ? prev[softwareKey] : []
      return {
        ...prev,
        [softwareKey]: [...prevSoftware, { seats: seatDiff, value, url, licenseKey }],
      }
    }
    return prev
  }, {})
}

export const diffLicenseSeats = (users, licenses) => {
  if (!users || !licenses) return {}
  const counts = countLicenses(users)
  return Object.entries(licenses).reduce((prev, [licenseKey, { seats, value, url }]) => {
    const seatDiff =
      Number(seats) === 0 ? 'Infinite Seats' : Number(seats) - (counts[licenseKey] ? counts[licenseKey] : 0)
    return {
      ...prev,
      [licenseKey]: { seats: seatDiff, value, url, licenseSeats: seats, count: counts[licenseKey] },
    }
  }, {})
}

export const getAvailableSeats = (users, licenses) =>
  diffSeats(users, Object.entries(licenses).filter(([_, { active }]) => active))

export const getKeysFromText = ({ addMultiple, softwareKey, url, seats }, getKey) =>
  addMultiple
    .split('\n')
    .map(str => str.trim())
    .filter(str => str && str !== '')
    .reduce(
      (prev, value) => ({
        ...prev,
        [getKey()]: { seats, url, softwareKey, value, active: true },
      }),
      {},
    )

export const dataToCsvURI = data =>
  encodeURI(`data:text/csv;charset=utf-8,${data.map(row => row.join(',')).join(`\n`)}`)

export const getCsvArr = users => [
  ['Email', 'First Name', 'Last Name'],
  ...Object.values(users).map(({ email, firstName, lastName }) => [email, firstName, lastName]),
]
