import firebase from 'firebase/app'
import React from 'react'
import { Header, Icon, Grid, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { reorder, getOrdered } from '../utils'
import ArticleDisplay from './ArticleDisplay'

const addArticle = (categorySlug, { title, slug }, order, clearForm) =>
  firebase.database().ref(`content/${categorySlug}/articles/${slug}`).set({ order, title }, clearForm)

const onDragEnd = (categorySlug, items) => ({ source, destination }) => {
  if (!destination) return
  reorder(items, source.index, destination.index).map((item, order) =>
    firebase.database().ref(`content/${categorySlug}/articles/${item}/order`).set(order),
  )
}

const ArticleEditor = ({ category, categorySlug, articleForm, updateFormField, clearForm, account }) => {
  const [showForm, updateShowForm] = React.useState(false)
  if (!account) return null
  const articleOrder = getOrdered(account.isAdmin, category.articles)
  return account && account.isAdmin ? (
    <DragDropContext onDragEnd={onDragEnd(categorySlug, articleOrder)}>
      <Droppable droppableId="articleEditor">
        {({ droppableProps, innerRef: draggableRef, placeholder }) => (
          <div ref={draggableRef} {...droppableProps} className="ui segments">
            {articleOrder.map((articleSlug, catIndex) => (
              <Draggable key={articleSlug} draggableId={articleSlug} index={catIndex}>
                {({ innerRef, draggableProps, dragHandleProps }) => (
                  <div ref={innerRef} {...draggableProps} className="ui segment">
                    <Link to={`/content/${categorySlug}/${articleSlug}`}>
                      <Header as="h3">
                        {category.articles[articleSlug].title}
                        <Icon
                          style={{ float: 'right', fontSize: 15 }}
                          name="arrows alternate vertical"
                          {...dragHandleProps}
                        />
                      </Header>
                    </Link>
                    <hr />
                    {category.articles[articleSlug].sections &&
                      Object.entries(category.articles[articleSlug].sections).map(([sectionSlug, section]) => (
                        <div key={articleSlug} style={{ margin: '10px auto' }}>
                          <Link to={`/content/${categorySlug}/${articleSlug}/${sectionSlug}`}>
                            <Header as="h4">{section.title}</Header>
                          </Link>
                        </div>
                      ))}
                  </div>
                )}
              </Draggable>
            ))}
            {placeholder}
            {showForm ? (
              <div className="ui secondary segment">
                <Form
                  onSubmit={() =>
                    addArticle(categorySlug, articleForm, articleOrder.length, () => {
                      updateShowForm(false)
                      clearForm()
                    })
                  }
                >
                  <Grid stackable columns={2}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Article Title</label>
                        <input
                          required
                          onChange={({ target: { value } }) => updateFormField('title', value)}
                          value={articleForm.title}
                          placeholder="Article Title"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Article Slug</label>
                        <input
                          required
                          onChange={({ target: { value } }) => updateFormField('slug', value)}
                          value={articleForm.slug}
                          placeholder="article-slug"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column>
                      <Button type="submit" primary>
                        Submit
                      </Button>
                      <Button type="submit" floated="right" onClick={() => updateShowForm(false)}>
                        Cancel
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </div>
            ) : (
              <div onClick={() => updateShowForm(true)} className="ui secondary segment" style={{ cursor: 'pointer' }}>
                <p>
                  <Icon name="plus" /> Add New Article
                </p>
              </div>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <ArticleDisplay category={category} articleOrder={articleOrder} categorySlug={categorySlug} />
  )
}
export default ArticleEditor
