import React from 'react'
import { Container } from 'semantic-ui-react'
import HomepageHeading from '../components/HomepageHeading'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'

const HomePage = ({ homePageMarkdown }) => (
  <div>
    <HomepageHeading />
    <Container style={{ paddingTop: 40, paddingBottom: 40 }}>
      <ReactMarkdown source={homePageMarkdown} escapeHtml={false} />
    </Container>
  </div>
)

export default connect(({ homePageMarkdown }) => ({ homePageMarkdown }))(HomePage)
