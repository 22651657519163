import firebase from 'firebase/app'
import React from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { UPDATE_AUTH_USER_INPUT } from '../redux'

const ResetPage = ({ authUserInput, updateAuthUserInput, reset }) => {
  const [passwordSent, updatePasswordSent] = React.useState(false)
  return (
    <Grid textAlign="center" style={{ minHeight: '100vh' }} verticalAlign="middle">
      {passwordSent ? (
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            Password Reset Email Sent!
          </Header>
          <p>Check your email and click the link to reset your password.</p>
        </Grid.Column>
      ) : (
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            Reset Your Password
          </Header>
          <Form size="large">
            <Segment>
              <p style={{ color: 'red' }}>{authUserInput.error}</p>
              <Form.Input
                fluid
                value={authUserInput.email}
                icon="mail"
                iconPosition="left"
                placeholder="E-mail address"
                type="email"
                onChange={({ target: { value } }) => updateAuthUserInput('email', value)}
              />
              <Button
                primary
                fluid
                size="large"
                onClick={() => reset(authUserInput.email).then(() => updatePasswordSent(true))}
              >
                Send Reset Email
              </Button>
            </Segment>
          </Form>
          <Message>
            Already know your password? <Link to="/login">Login</Link>
          </Message>
        </Grid.Column>
      )}
    </Grid>
  )
}

export default connect(
  ({ authUserInput }) => ({ authUserInput }),
  (d) => ({
    updateAuthUserInput: (key, value) => d({ type: UPDATE_AUTH_USER_INPUT, payload: { [key]: value, error: '' } }),
    reset: (email) =>
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .catch((error) => {
          console.warn(error)
          d({ type: UPDATE_AUTH_USER_INPUT, payload: { error: error.message } })
        }),
  }),
)(ResetPage)
