import firebase from 'firebase/app'
import React from 'react'
import { Header, Icon, Table, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { UPDATE_FORM } from '../redux'
import SoftwareForm from '../components/SoftwareForm'
import FirebaseToggle from '../components/FirebaseToggle'

const SoftwareTable = ({ software, updateFormFields, isActive }) => {
  const [showForm, updateShowForm] = React.useState(false)
  const [editingKey, updateEditingKey] = React.useState(null)
  const items = software ? Object.entries(software).filter(([_, { active }]) => active === isActive) : []
  return (
    <Table celled unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Active</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map(([key, { title, active }]) => (
          <Table.Row key={key}>
            <Table.Cell>
              <Header as="h4">{title}</Header>
            </Table.Cell>
            <Table.Cell collapsing>
              <FirebaseToggle label="" size="small" value={active} path={`software/${key}/active`} />
            </Table.Cell>
            <Table.Cell collapsing>
              <Button
                icon="pencil"
                onClick={() => {
                  updateEditingKey(key)
                  updateFormFields({ title })
                  updateShowForm(true)
                }}
              />
              <Button
                icon="close"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this software?')) {
                    firebase
                      .database()
                      .ref('software/' + key)
                      .remove()
                  }
                }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row style={{ cursor: 'pointer' }} onClick={() => (showForm ? null : updateShowForm(true))}>
          <Table.HeaderCell colSpan="3">
            {showForm ? (
              <SoftwareForm
                editingKey={editingKey}
                hideForm={() => {
                  updateShowForm(false)
                  updateEditingKey(null)
                }}
              />
            ) : (
              <span style={{ color: 'grey' }}>
                <Icon name="plus" /> Add New Software
              </span>
            )}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}

export default connect(
  ({ software }) => ({ software }),
  (d) => ({
    updateFormFields: (payload) => d({ type: UPDATE_FORM, formType: 'softwareForm', payload }),
  }),
)(SoftwareTable)
