import React from 'react'
import { Header } from 'semantic-ui-react'
import { getOrdered, getFirstInArray } from '../utils'
import SectionMarkdown from './SectionMarkdown'

export default ({ articleOrder, category }) => {
  const firstArticleSlug = getFirstInArray(articleOrder)
  const firstSectionSlug = getFirstInArray(getOrdered(false, category.articles[firstArticleSlug].sections))
  return (
    <>
      <Header
        as="h1"
        style={{
          margin: '30px auto 0px',
        }}
      >
        {category.articles[firstArticleSlug].title}
      </Header>
      <Header
        as="h2"
        style={{
          margin: '0px auto 30px',
        }}
      >
        {category.articles[firstArticleSlug].sections[firstSectionSlug].title}
      </Header>
      <SectionMarkdown sectionSlug={firstSectionSlug} />
    </>
  )
}
