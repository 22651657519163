import firebase from 'firebase/app'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Breadcrumb, Header, Icon, Form, Grid } from 'semantic-ui-react'
import FirebaseToggle from '../components/FirebaseToggle'
import MarkdownEditor from '../components/MarkdownEditor'
import SectionDisplay from '../components/SectionDisplay'
import ContentMenu from '../components/ContentMenu'

const SectionPage = (props) => {
  const [showEdit, updateShowEdit] = React.useState(false)
  const [titleField, updateTitleField] = React.useState('')
  const [sectionMarkdown, updateSectionMarkdown] = React.useState('')
  const { account, section, categorySlug, articleSlug, categoryTitle, articleTitle, sectionSlug, history } = props
  return account && account.isAdmin ? (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <ContentMenu categorySlug={categorySlug} articleSlug={articleSlug} sectionSlug={sectionSlug} />
        </Grid.Column>
        <Grid.Column width={10}>
          <Breadcrumb>
            <Breadcrumb.Section>
              <Link to={`/content`}>Contents</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>
              <Link to={`/content/${categorySlug}`}>{categoryTitle}</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>
              <Link to={`/content/${categorySlug}/${articleSlug}`}>{articleTitle}</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>{section.title}</Breadcrumb.Section>
          </Breadcrumb>
          <Header
            as="h3"
            style={{
              fontSize: '2.7em',
              fontWeight: 'normal',
              marginBottom: 0,
            }}
          >
            {showEdit ? (
              <Form
                size="massive"
                onSubmit={() => {
                  firebase
                    .database()
                    .ref(`content/${categorySlug}/articles/${articleSlug}/sections/${sectionSlug}/title`)
                    .set(titleField)
                  firebase.database().ref(`sections/${sectionSlug}/markdown`).set(sectionMarkdown)
                  updateTitleField('')
                  updateShowEdit(false)
                }}
              >
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    onChange={({ target: { value } }) => updateTitleField(value)}
                    value={titleField}
                    placeholder="Title"
                  />
                  <Form.Button floated="right" primary type="submit">
                    Save
                  </Form.Button>
                </Form.Group>
              </Form>
            ) : (
              <>
                {section.title}
                <sup>
                  <Icon
                    style={{ fontSize: 15, marginLeft: 20, cursor: 'pointer' }}
                    onClick={() => {
                      updateShowEdit(true)
                      updateTitleField(section.title)
                    }}
                    name="pencil"
                  />
                  <Icon
                    style={{ fontSize: 15, marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this section?')) {
                        firebase
                          .database()
                          .ref(`content/${categorySlug}/articles/${articleSlug}/sections/${sectionSlug}`)
                          .remove()
                        history.push(`/content/${categorySlug}/${articleSlug}`)
                      }
                    }}
                    name="trash"
                  />
                </sup>
                <span style={{ float: 'right', fontSize: 14 }}>
                  <FirebaseToggle
                    label="Admin Only"
                    size="small"
                    path={`sections/${sectionSlug}/admin`}
                    path2={`content/${categorySlug}/articles/${articleSlug}/sections/${sectionSlug}/admin`}
                  />
                </span>
              </>
            )}
          </Header>
          <MarkdownEditor
            editing={showEdit}
            sectionMarkdown={sectionMarkdown}
            updateSectionMarkdown={updateSectionMarkdown}
            sectionSlug={sectionSlug}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <SectionDisplay sectionSlug={sectionSlug} {...props} />
  )
}

export default connect(({ content, account }, { match: { params: { categorySlug, articleSlug, sectionSlug } } }) => ({
  account,
  article: content ? content[categorySlug].articles[articleSlug] : {},
  section: content ? content[categorySlug].articles[articleSlug].sections[sectionSlug] : {},
  categorySlug,
  articleSlug,
  sectionSlug,
  categoryTitle: content ? content[categorySlug].title : '',
  articleTitle: content ? content[categorySlug].articles[articleSlug].title : '',
}))(SectionPage)
