import React from 'react'
import { Route } from 'react-router-dom'
import CategoryPage from '../pages/CategoryPage'
import ArticlePage from '../pages/ArticlePage'
import CategoryEditor from '../components/CategoryEditor'
import SectionPage from './SectionPage'

const ContentPage = () => (
  <div style={{ padding: '40px 10px', minHeight: '100vh' }}>
    <Route exact path="/content" component={CategoryEditor} />
    <Route exact path="/content/:categorySlug" component={CategoryPage} />
    <Route exact path="/content/:categorySlug/:articleSlug" component={ArticlePage} />
    <Route exact path="/content/:categorySlug/:articleSlug/:sectionSlug" component={SectionPage} />
  </div>
)

export default ContentPage
