import firebase from 'firebase/app'
import React from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { UPDATE_AUTH_USER_INPUT } from '../redux'

const RequestAccessPage = ({ authUserInput, updateAuthUserInput, requestAccess, history }) => (
  <Grid textAlign="center" style={{ minHeight: '100vh' }} verticalAlign="middle">
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as="h1" textAlign="center">
        Request Access
      </Header>
      <Form size="large">
        <Segment>
          <p style={{ color: 'red' }}>{authUserInput.error}</p>
          <Form.Input
            fluid
            required
            value={authUserInput.email}
            placeholder="E-mail address"
            type="email"
            onChange={({ target: { value } }) => updateAuthUserInput('email', value)}
          />
          <Form.Input
            required
            fluid
            value={authUserInput.firstName}
            placeholder="First Name"
            onChange={({ target: { value } }) => updateAuthUserInput('firstName', value)}
          />
          <Form.Input
            fluid
            required
            value={authUserInput.lastName}
            placeholder="Last Name"
            onChange={({ target: { value } }) => updateAuthUserInput('lastName', value)}
          />
          <Form.Input
            fluid
            value={authUserInput.password}
            placeholder="Password"
            type="password"
            onChange={({ target: { value } }) => updateAuthUserInput('password', value)}
          />
          <Form.Input
            fluid
            value={authUserInput.passwordConfirm}
            placeholder="Confirm Password"
            type="password"
            onChange={({ target: { value } }) => updateAuthUserInput('passwordConfirm', value)}
          />
          <Form.Input
            fluid
            required
            value={authUserInput.workshopCode}
            placeholder="Workshop Code"
            maxLength={4}
            onChange={({ target: { value } }) => updateAuthUserInput('workshopCode', value.toLowerCase())}
          />
          <Button primary fluid size="large" onClick={() => requestAccess(authUserInput, history)}>
            Request Access
          </Button>
        </Segment>
      </Form>
      <Message>
        Already have an account? <Link to="/login">Login</Link>
      </Message>
    </Grid.Column>
  </Grid>
)

export default connect(
  ({ authUserInput }) => ({ authUserInput }),
  (d) => ({
    updateAuthUserInput: (key, value) => d({ type: UPDATE_AUTH_USER_INPUT, payload: { [key]: value, error: '' } }),
    requestAccess: (authUserInput, history) => {
      if (authUserInput.password !== authUserInput.passwordConfirm)
        return d({
          type: UPDATE_AUTH_USER_INPUT,
          payload: { error: 'Password does not match the confirmation password' },
        })
      if (authUserInput.firstName === '' || authUserInput.lastName === '')
        return d({ type: UPDATE_AUTH_USER_INPUT, payload: { error: 'First name and last name are required' } })
      firebase
        .auth()
        .createUserWithEmailAndPassword(authUserInput.email, authUserInput.password)
        .then(() =>
          firebase
            .auth()
            .signInWithEmailAndPassword(authUserInput.email, authUserInput.password)
            .then(({ user }) => {
              firebase
                .database()
                .ref(`users/${user.uid}`)
                .update({
                  email: authUserInput.email,
                  firstName: authUserInput.firstName,
                  lastName: authUserInput.lastName,
                  phone: authUserInput.phone,
                  createdDate: Date.now(),
                  workshops:
                    authUserInput.workshopCode && authUserInput.workshopCode !== ''
                      ? { [authUserInput.workshopCode]: Date.now() }
                      : null,
                })
                .then(() => history.push('/'))
            }),
        )
        .catch((error) => {
          console.warn(error)
          d({ type: UPDATE_AUTH_USER_INPUT, payload: { error: error.message } })
        })
    },
  }),
)(RequestAccessPage)
