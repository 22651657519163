import React from 'react'
import SettingsTable from '../components/SettingsTable'
import { Container, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'
import UpsertSettingModal from '../components/UpsertSettingModal'
import HomePageEditor from '../components/HomePageEditor'

const SettingsPage = ({ settings, account }) => {
  if (!account || !account.isAdmin) return null
  return (
    <Container style={{ padding: '40px 10px', minHeight: '100vh' }}>
      <Header
        as="h3"
        style={{
          fontSize: '2.7em',
          fontWeight: 'normal',
          marginBottom: 0,
        }}
      >
        Site Settings
      </Header>
      <div style={{ float: 'right', position: 'relative', bottom: 40 }}>
        <UpsertSettingModal />
      </div>
      <SettingsTable settings={settings} />
      <br />
      <br />
      <hr />
      <HomePageEditor />
    </Container>
  )
}
export default connect(({ settings, account }) => ({ settings, account }))(SettingsPage)
