import firebase from 'firebase/app'
import React from 'react'
import { Icon, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import moment from 'moment'

const WarningsList = ({ warnings }) => {
  if (!warnings) return null
  return (
    <Segment.Group>
      {Object.entries(warnings).map(([key, warning]) => (
        <Segment key={key} color="yellow">
          <h4>
            {warning.message}&nbsp;&nbsp;
            <small style={{ color: 'grey' }}>{moment(warning.date, 'x').fromNow()}</small>
            <Icon
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => firebase.database().ref(`warnings/${key}`).remove()}
              name="close"
            />
            <br />
            <small style={{ color: 'grey' }}>{warning.meta ? JSON.stringify(warning.meta) : null}</small>
          </h4>
        </Segment>
      ))}
    </Segment.Group>
  )
}
export default connect(({ warnings }) => ({ warnings }))(WarningsList)
