import React from 'react'
import { Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'

const date = new Date()

const Footer = ({ settings }) => (
  <Segment
    inverted
    vertical
    style={{
      backgroundColor: window.location.hostname.includes('pesrtraining') ? '#12283d' : null,
      padding: 20,
      marginTop: 20,
    }}
  >
    <p style={{ float: 'right' }}>
      <strong>Privacy Policy: </strong>
      {settings ? settings.PrivacyPolicy.value : ''}
    </p>
    &copy; Copyright {date.getFullYear()} Tradewind Technologies Inc.
  </Segment>
)

export default connect(({ settings }) => ({ settings }))(Footer)
