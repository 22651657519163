import firebase from 'firebase/app'
import React from 'react'
import { Button, Modal, Form } from 'semantic-ui-react'

const UpsertSettingModal = ({ settingKey, settingValue = '' }) => {
  const [modalOpen, updateModalOpen] = React.useState(false)
  const [newSettingKey, updateNewSettingKey] = React.useState('')
  const [setting, updateSetting] = React.useState(settingValue)
  return (
    <Modal
      open={modalOpen}
      size="mini"
      trigger={<Button onClick={() => updateModalOpen(true)} icon={settingKey ? 'pencil' : 'plus'} />}
    >
      <Modal.Header>{settingKey ? `Update Setting - ${settingKey}` : 'Add Setting'}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={() =>
            firebase
              .database()
              .ref(`settings/${settingKey ? settingKey : newSettingKey}/value`)
              .set(setting)
              .then(() => {
                updateNewSettingKey('')
                updateSetting(settingValue)
                updateModalOpen(false)
              })
          }
        >
          {settingKey ? null : (
            <Form.Field>
              <input
                autoFocus
                tabIndex={-1}
                placeholder="New Setting Key"
                value={newSettingKey}
                onChange={({ target: { value } }) => updateNewSettingKey(value)}
              />
            </Form.Field>
          )}
          <Form.Field>
            <input
              placeholder="New Setting"
              value={setting}
              onChange={({ target: { value } }) => updateSetting(value)}
            />
          </Form.Field>
          <Button fluid size="large" primary style={{ marginTop: 12 }} type="submit">
            Save
          </Button>
        </Form>
        <Button
          onClick={() => {
            updateNewSettingKey('')
            updateSetting(settingValue)
            updateModalOpen(false)
          }}
          basic
          fluid
          style={{ marginTop: 10 }}
          type="button"
        >
          Cancel
        </Button>
      </Modal.Content>
    </Modal>
  )
}

export default UpsertSettingModal
