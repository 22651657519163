import React from 'react'
import { Route } from 'react-router-dom'
import WorkshopsList from '../components/WorkshopsList'
import WorkshopDetailPage from './WorkshopDetailPage'
import { Container } from 'semantic-ui-react'

const WorkshopsPage = () => (
  <Container style={{ padding: '40px 10px', minHeight: '100vh' }}>
    <Route exact path="/workshops" component={WorkshopsList} />
    <Route path={`/workshops/:workshopId`} component={WorkshopDetailPage} />
  </Container>
)

export default WorkshopsPage
